
.modal__content-width-cover
  // width: 100%
  // max-width: 450px
  &__cover
    height: 170px
    @apply bg-grey-lightest bg-center bg-no-repeat bg-cover
  &__content
    max-height: 400px
    -webkit-overflow-scrolling: touch
    overflow-y: auto
    ul
      @apply pl-24
    &__title
      @apply flex flex-wrap items-center
      &__iconStyle
        @apply mr-4 text-2xl inline-block
        &.success
          @apply text-green
        &.warning
          @apply text-orange
        &.error
          @apply text-red
  .tr.large
    min-width: 100%
    &.actived
      @apply text-white bg-red font-bold
  &__footer
    @apply border-t-1 border-grey-light
.cancelModal
  max-height: 60vh
  overflow-y: auto
  @apply bg-grey-lightest
  .table .thead .td
    padding: 16px 12px
  .table .tbody .tr .td
    padding: 12px
  .tr.actived:hover
    .td
      @apply bg-red
