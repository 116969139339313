
.template__info-group
  @apply flex flex-col
  label
    @apply text-base text-grey-darkest mb-8
  span
    @apply text-sm text-grey-dark
  &:not(:last-of-type)
    @apply mb-24

.transfer-info
  .flex-table
    @apply text-sm text-grey-darkest
    &__row
      @apply flex mb-8

.order-info
  &__detail
    &__amounts
      .template__info-group
        &:not(:last-of-type)
          @apply mb-24
        @screen md
          @apply flex-1 mb-0 #{!important}
          &:not(:first-of-type)
            @apply pl-24
          &:not(:last-of-type)
            @apply border-r-1 border-grey-lighter
    .used-coupons
      .coupon-container
        // &:not(:last-of-type)
        .coupon-detail
          @screen md
            @apply mr-16

        .coupon-detail
          border-left: solid 8px #a9d341
          @apply flex items-center justify-between
          label
            @apply text-sm m-0 text-grey-darker
          span
            @apply text-base text-green-darker

.hotel-orders
  border-color: #f2f4f6
  &__order
    &:not(:last-of-type)
      @apply border-b-1 border-grey-lighter

.addon-orders
  &__order
    &:not(:last-of-type)
      @apply border-b-1 border-grey-lighter
.captcha
  position: relative
  margin-left: 2px
  &--loading
    &::before
      content: 'Loading...'
      color: black
      padding: 8px 24px
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 93%
      z-index: 20
      background-color: white
      opacity: 0.7
