
.form-group
  @apply mb-16
  label
    @apply block mb-8 text-sm text-grey-darkest
  input
    @apply w-full

  &.error
    label
      @apply text-red
